import { nanoid } from "nanoid";
import { create } from "zustand";

const projects = [
  {
    id: nanoid(),
    name: "YK Office",
    loc: "Maidan, Ankara 2022",
    images: [
      "/assets/projects/YK Office/1 (1).JPG",
      "/assets/projects/YK Office/1 (2).JPG",
      "/assets/projects/YK Office/1 (3).JPG",
      "/assets/projects/YK Office/1 (4).JPG",
      "/assets/projects/YK Office/1 (5).JPG",
      "/assets/projects/YK Office/1 (6).JPG",
      "/assets/projects/YK Office/1 (7).JPG",
      "/assets/projects/YK Office/1 (9).JPG",
      "/assets/projects/YK Office/1 (10).JPG",
      "/assets/projects/YK Office/1 (11).JPG",
      "/assets/projects/YK Office/1 (12).JPG",
      "/assets/projects/YK Office/1 (13).JPG",

      "/assets/projects/YK Office/1 (15).JPG",

      "/assets/projects/YK Office/1 (17).JPG",
      "/assets/projects/YK Office/1 (18).JPG",
      "/assets/projects/YK Office/1 (19).JPG",

      "/assets/projects/YK Office/1 (21).JPG",
      "/assets/projects/YK Office/1 (22).JPG",
      "/assets/projects/YK Office/1 (23).JPG",
    ],
  },
  {
    id: nanoid(),
    name: "Pariba Coffee",
    loc: "Çankaya, Ankara 2021",
    images: [
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam03 (1).jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam04.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam05 (1).jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam08 (1).jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam10.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam15.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam16.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam20 bitkili.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/cam20.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/dısarı render 1.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/dısarı render 2.jpg",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/kabartma logolu.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc0.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc1neonlu.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc2.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc3.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc4.png",
      "/assets/projects/Bestekar Sokak - Pariba Cafe/wc5.png",
    ],
  },
  {
    id: nanoid(),
    name: "A.A Evi",
    loc: "Edit Lake Villalari, Golbasi, Ankara 2022",
    images: [
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (1).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (1).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (1).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (10).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (2).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (3).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (4).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (5).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (6).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (8).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2 (9).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Emir Oda/ODA_2_YENİ (2).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (2).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (3).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (4).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (5).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (6).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (7).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (8).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Kaan Oda/ODA_1 (9).PNG",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (2).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (3).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (4).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (5).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (6).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (7).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (8).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (9).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (10).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (11).png",
      "/assets/projects/Edit Lake Villaları - Altınel Evi/Çatı Katı/RENDER-1 (12).png",
    ],
  },
  {
    id: nanoid(),
    name: "Luxo Diamond",
    loc: "Panora AVM",
    images: [
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9456.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9458.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9459.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9463.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9464.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9468.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9469.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9470.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9472.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9477.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9478.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9479.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9481.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9485.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9493.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9494.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9497.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9500.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9513.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9515.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9516.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9518.jpg",
      "/assets/projects/Panora AVM - Luxo Diamond/DSCF9521.jpg",
    ],
  },
  {
    id: nanoid(),
    name: "B.M Evi",
    loc: "Park Joven",
    images: [
      "/assets/projects/Park Joven - Murt Evi/1 (1).png",
      "/assets/projects/Park Joven - Murt Evi/1 (10).png",
      "/assets/projects/Park Joven - Murt Evi/1 (11).png",
      "/assets/projects/Park Joven - Murt Evi/1 (2).png",
      "/assets/projects/Park Joven - Murt Evi/1 (3).png",
      "/assets/projects/Park Joven - Murt Evi/1 (4).png",
      "/assets/projects/Park Joven - Murt Evi/1 (5).png",
      "/assets/projects/Park Joven - Murt Evi/1 (6).png",
      "/assets/projects/Park Joven - Murt Evi/1 (7).png",
      "/assets/projects/Park Joven - Murt Evi/1 (8).png",
      "/assets/projects/Park Joven - Murt Evi/1 (9).png",
      "/assets/projects/Park Joven - Murt Evi/1 (10).png",
      "/assets/projects/Park Joven - Murt Evi/1 (11).png",
      "/assets/projects/Park Joven - Murt Evi/2 (1).png",
      "/assets/projects/Park Joven - Murt Evi/2 (2).png",
      "/assets/projects/Park Joven - Murt Evi/2 (3).png",
      "/assets/projects/Park Joven - Murt Evi/2 (4).png",
      "/assets/projects/Park Joven - Murt Evi/2 (5).png",
      "/assets/projects/Park Joven - Murt Evi/3 (1).png",
      "/assets/projects/Park Joven - Murt Evi/3 (2).png",
      "/assets/projects/Park Joven - Murt Evi/3 (3).png",
      "/assets/projects/Park Joven - Murt Evi/3 (4).png",
      "/assets/projects/Park Joven - Murt Evi/3 (5).png",
      "/assets/projects/Park Joven - Murt Evi/3 (6).png",
      "/assets/projects/Park Joven - Murt Evi/3 (7).png",
      "/assets/projects/Park Joven - Murt Evi/3 (8).png",
    ],
  },
  {
    id: nanoid(),
    name: "La Pace Gym",
    loc: "Park Oran Ofis, Ankara 2022 ",
    images: [
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (1).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (2).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (3).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (4).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (5).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (6).png",
      "/assets/projects/Park Oran Ofis - La Pace Gym/1 (7).png",
    ],
  },
  {
    id: nanoid(),
    name: "Sante Prime",
    loc: " Golbasi, Ankara. 2023 Facade and environmental design",
    images: [
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 (1).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 (2).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 (3).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 (4).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 (5).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/1 son2.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/2 (2).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/2 (4).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/2 (5).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/2 (8).png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/2son2.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/3son.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/4son.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/5son.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/6son.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/7son.png",
      "/assets/projects/Sante Prime - Cephe ve Peyzaj Tasarımı - Kurumsal Kimlik Tasarımı/8son.png",
    ],
  },
  {
    id: nanoid(),
    name: "Kaff Diamond",
    loc: "Yalıkavak Marina, Bodrum, Mugla 2020",
    images: [
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/1.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/2.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/3.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/4.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/5.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/6.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/7-2.jpg",
      "/assets/projects/Yalıkavak Marina - Kaff Diamond/8.jpg",
    ],
  },
  {
    id: nanoid(),
    name: "Luxo Diamond",
    loc: "Yalikavak Marina, Marina, Bodrum, Mugla 2020",
    images: [
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (1).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (10).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (11).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (2).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (2).jpg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (3).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (3).jpg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (4).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (5).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (6).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (7).jpeg",
      "/assets/projects/Yalıkavak Marina - Luxo Diamond/1 (8).jpeg",
    ],
  },
];

const useStore = create((set) => ({
  projects: projects,
  selectedProject: projects[0],
  handleProjectClick: (project) =>
    set((state) => ({ selectedProject: project })),
}));

export { useStore };
